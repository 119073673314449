import React, { useState } from 'react';
// import Settings, { DefaultSettingsT, SettingsT } from 
// import Settings, { DefaultSettingsT, SettingsT } from './Settings';
import Carousel from 'react-material-ui-carousel';
import '../style/CarouselTop.scss';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Button,
} from '@mui/material';

// carousel images
import ProcessDashboardImage from '../assets/images/carousel-images/Process-Dashboard_edited.jpg';
import AnalyticsImage from '../assets/images/HANSA-Screens-web/Analytics-and-process-dashboard-small.png';
import RapidDeploymentImage from '../assets/images/carousel-images/rapid-deployment-large.png';
import BestPracticesImage from '../assets/images/carousel-images/best-practices-small.png';
import EInvoiceImage from '../assets/images/carousel-images/e-invoicing2-small.png';
import GSTImage from '../assets/images/carousel-images/GST-Tax-small.png';



const CarouselTop = () => {

    // const [settings, setSettings] = useState<SettingsT>(DefaultSettingsT);

    return (
        // <div style={{ marginTop: "0px", color: "#a9a9a9" }}>
        <div style={{ marginTop: "20px", color: "#a9a9a9" }}>
            <Typography variant='h4' align='center'>erp2enterprise  
            <span style={{ color: '#00adb3', fontStyle: 'italic' }}> HANSA</span>
            </Typography>
            <Typography variant='h6' align='center'> automates process-driven enterprises to scale</Typography>
            <br/>
        
            <Carousel
                className="carousel-top"
                // {...settings}
                // next={(now: any, previous:any) => console.log(`Next User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}
                // prev={(now, previous) => console.log(`Prev User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}
                // onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}
                
                // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
                // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
                // indicatorContainerProps={{style: {margin: "20px"}}}
                // NextIcon='next'
            >
                {
                    items.map((item, index) => {
                        return <Banner item={item} key={index} contentPosition={item.contentPosition} />
                    })
                }
            </Carousel>
            <br/>
            {/* <Settings settings={settings} setSettings={setSettings}/> */}
        </div>
    );
}


type Item = {
    Name: string,
    Caption: string,
    contentPosition: "left" | "right" | "middle",
    Items: {Name: string, Image: string}[]
}

interface BannerProps
{
    item: Item,
    contentPosition: "left" | "right" | "middle",
    length?: number,

}


const Banner = (props: BannerProps) => {

    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems: number = props.length ? props.length : 3;
    const mediaLength = totalItems - 1;

    let items = [];
    const content = (
        <Grid item xs={4} key="content">
            <CardContent className="Content">
                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption" sx={{ display: { xs: 'none', sm: 'block' }}}>
                    {props.item.Caption}
                </Typography>

                {/* <Button variant="outlined" className="ViewButton">
                    View Now
                </Button> */}
            </CardContent>
        </Grid>
    )


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={4} key={item.Name}>
                <CardMedia
                    className="Media"
                    image={item.Image}
                    title={item.Name}
                >
                    <Typography className="MediaCaption">
                        {item.Name}
                    </Typography>
                </CardMedia>

            </Grid>
        )

        items.push(media);
    }

    if (contentPosition === "left") {
        items.unshift(content);
    } else if (contentPosition === "right") {
        items.push(content);
    } else if (contentPosition === "middle") {
        items.splice(items.length / 2, 0, content);
    }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items: Item[] = [
    {
        Name: "Collab",
        Caption: "Inbuilt process collaboration framework",
        contentPosition: "left",
        Items: [
            {
                Name: "Process Dashboard",
                // Image: "https://source.unsplash.com/featured/?dashboard"
                Image: ProcessDashboardImage
            },
            {
                Name: "Analytics",
                // Image: "https://source.unsplash.com/featured/?graphs"
                Image: AnalyticsImage
            }
        ]
    },
    {
        Name: "Config",
        Caption: "Agile configuration with low-code no-code technology",
        contentPosition: "middle",
        Items: [
            {
                Name: "Rapid Deployment",
                // Image: "https://source.unsplash.com/featured/?office"
                Image: RapidDeploymentImage
            },
            {
                Name: "Best Practices",
                // Image: "https://source.unsplash.com/featured/?business"
                Image: BestPracticesImage
            }
        ]
    },
    {
        Name: "Comply",
        Caption: "Online eInvoice and GST compliance",
        contentPosition: "right",
        Items: [
            {
                Name: "e-Invoice",
                // Image: "https://source.unsplash.com/featured/?revenue"
                Image: EInvoiceImage
            },
            {
                Name: "GST",
                // Image: "https://source.unsplash.com/featured/?tax"
                Image: GSTImage
            }
        ]
    }
]


export default CarouselTop;