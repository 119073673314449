import React from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import { Card, CardHeader, CardMedia, CardContent } from '@mui/material';
import TeamImage1 from '../assets/images/carousel-images/pexels-thisisengineering-small.png';
import TeamImage2 from '../assets/images/carousel-images/tech2-large.png';
import TeamImage3 from '../assets/images/carousel-images/best-practices-small.png';
import TeamImage4 from '../assets/images/carousel-images/pexels-thisisengineering-small.png';


const Teams = () => {
  return (
    <>

        {/* Section Header */}
        <Container>
            <Typography align="center" variant="h4"  > Teams </Typography>
            <Typography align="center" variant="subtitle1" paragraph> excellence and innovation units </Typography>
            <br />
        </ Container >

        {/* Container for cards */}
        <Container>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>

            {/* Grid item 1 - product innivation unit */}
            <Grid item xs={12} sm={6} md={3} >

              <Card>
                <CardMedia
                   component="img"
                   height="220"
                   image={"https://erp2enterprise.com/site-images/teams-product-innovation.jpg"}
                   alt="Unit 1"
                />

                {/* <CardHeader
                  title="Product Innovation"
                  subheader="Leverages state-of-the-art technologies
                  and innovations for customer benefits"
                /> */}
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>Product Innovation</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>Leverages state-of-the-art technologies
                  and innovations for customer benefits</Typography>
                </CardContent>

             
              </Card>

            </Grid>

            {/* Grid item 2 - Labs */}
            <Grid item xs={12} sm={6} md={3} >
            <Card>
                <CardMedia
                   component="img"
                   height="220"
                   image={"https://erp2enterprise.com/site-images/teams-erp2enterprise-labs.jpg"}
                   alt="Unit 1"
                />

                {/* <CardHeader
                  title="Product Innovation"
                  subheader="Leverages state-of-the-art technologies
                  and innovations for customer benefits"
                /> */}
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>erp2enterprise Labs</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                    Automated with agile
                    SCRUM system and follows CI/CD to
                    ensure fastest adoption of changes.
                  </Typography>
                </CardContent>
             
              </Card>      
            </Grid>

            {/* Grid item 3 - Customers success management unit*/}
            <Grid item xs={12} sm={6} md={3} >
            <Card>
                <CardMedia
                   component="img"
                   height="220"
                   image={"https://erp2enterprise.com/site-images/teams-customer-success-mgmt.jpg"}
                   alt="Unit 1"
                />

                {/* <CardHeader
                  title="Product Innovation"
                  subheader="Leverages state-of-the-art technologies
                  and innovations for customer benefits"
                /> */}
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>Customer Success Management</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                  Redefining customer support by ensuring
                  successful adoption and maintenance of best practices.
                  </Typography>
                </CardContent>
        
              </Card>
            </Grid>

            {/* Grid item 4 -Implementation */}
            <Grid item xs={12} sm={6} md={3} >
            <Card>
                <CardMedia
                   component="img"
                   height="220"
                   image={"https://erp2enterprise.com/site-images/teams-implementation.jpg"}
                   alt="Unit 1"
                />

                {/* <CardHeader
                  title="Product Innovation"
                  subheader="Leverages state-of-the-art technologies
                  and innovations for customer benefits"
                /> */}
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>Implementation</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                    Bringing 20+ years of expertise in ERP practices. 
                    Ensures lightning fast implementation to bring quick ROI.
                  </Typography>
                </CardContent>
  
              </Card>
            </Grid>

          </Grid> {/* Grid container ends */}
          
        </Container>
        <br /> 
        <br/>
      
    </>
  )
}

export default Teams
