import React from 'react';
import { Grid, Container, Typography } from "@mui/material";

import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';   //  agility
import CodeOffOutlinedIcon from '@mui/icons-material/CodeOffOutlined';  // flexibility
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'; // Maintainability
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined'; // Auditability

// [09:58, 03/03/2023] Suvranshu Kar: import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
// [10:03, 03/03/2023] Suvranshu Kar: Flexibility
// [10:03, 03/03/2023] Suvranshu Kar: import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
// [10:03, 03/03/2023] Suvranshu Kar: Maintainability
// [10:03, 03/03/2023] Suvranshu Kar: import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
// [10:04, 03/03/2023] Suvranshu Kar: Auditability
// [10:04, 03/03/2023] Suvranshu Kar: import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
// [10:09, 03/03/2023] Suvranshu Kar: flexibility
// [10:09, 03/03/2023] Suvranshu Kar: import CodeOffOutlinedIcon from '@mui/icons-material/CodeOffOutlined';
// [10:14, 03/03/2023] Suvranshu Kar: import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';



const Advantages = () => {
  return (
    <div>
        {/* Section Header */}
        <Container>
            <Typography align="center" variant="h4"  > The HANSA Advantage </Typography>
            <Typography align="center" variant="subtitle1" paragraph> 
              benefits of low-code / no-code innovation
           </Typography>
            <br />

        </Container>

        <Container>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
            {/* Grid item 1 - Agility */}
            <Grid item xs={12} sm={6} md={3} >
              <Typography align="center" > <SyncOutlinedIcon fontSize='large'/> </Typography>
              <Typography align="center" variant="h6"  >  Agility </Typography>
              <Typography align="center" variant="subtitle1" color={'GrayText'} paragraph>
              Processes and steps are
              definable without writing a single
              line of custom code - with no-code framework.
              </Typography>

            </Grid>

            {/* Grid item 2 - Flexibility */}
            <Grid item xs={12} sm={6} md={3} >
               <Typography align="center" > <CodeOffOutlinedIcon fontSize='large'/>
               </Typography>
              <Typography align="center" variant="h6"  > Flexibility </Typography>
              <Typography align="center" variant="subtitle1" color={'GrayText'} paragraph>
                You can define all order processing and invoicing
                documents - best suited for you from
                prefabricated templates.
              </Typography>

            </Grid>

            {/* Grid item 3 - Maintainability */}
            <Grid item xs={12} sm={6} md={3} >
              <Typography align="center" > <InventoryOutlinedIcon fontSize='large'/> </Typography>
              <Typography align="center" variant="h6"  > Maintainability</Typography>
              <Typography align="center" variant="subtitle1" color={'GrayText'} paragraph> 
              Manage issues with in-built service-
              ticket tracking system. The unique
              low-code / no-code framework
              increases reliability.
              </Typography>

            </Grid>

            {/* Grid item 4 - Auditability */}
            <Grid item xs={12} sm={6} md={3} >
              <Typography align="center" > <ContentPasteSearchOutlinedIcon fontSize='large'/> </Typography>
              <Typography align="center" variant="h6"  > Audit Trail </Typography>
              <Typography align="center" variant="subtitle1" color={'GrayText'} paragraph> 
                Track all important
                documents and where they are stuck
                up. Audit trail of any data point
                modification is maintained.
              </Typography>

            </Grid>

          </Grid> {/* Grid container ends */}
          
        </Container>
    <br />
      
    </div>
  )
}

export default Advantages
