import React from 'react';
import { Typography, Container, Grid,  Divider, Box } from '@mui/material';
import { List,  ListItem, ListItemButton, IconButton } from '@mui/material';
import {Card, CardContent,  CardMedia, CardActions} from '@mui/material';
import FooterImage1 from '../assets/images/Download-HANSA-Advantage-Brochure.png';
import Copyright from './Copyright';
import logo from '../assets/images/erp2enterprise-logo.png';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';  // Download icon

// import  HANSABrochure  from '../assets/lits/HANSABrochure.pdf';





const Footer = () => {

  const navigate = useNavigate();

// function for scrolling to the sections 
const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({behavior: 'smooth'});

  }
};

  return (
    <>
      <div style={{backgroundColor: "hsla(0, 0%, 50%, 0.2)"}}>
        {/* Container 1 */}
        <Container>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
            {/* Grid item 1 - HANSA advantage Brochure */}
           
            <Grid item xs={12} sm={6} md={3} >
           
            <div>
            {/* <a href="https://erp2enterprise.com/brochures/erp2enterprise-HANSA-brochure.pdf" target={'_blank'}> */}
            <Card>
                <CardMedia
                   component="img"
                   height="120"
                   image={"https://erp2enterprise.com/site-images/HANSA-brochure-small.png"}
                   alt="Unit 1"
                />
           
                
                <CardContent>
                  <Typography variant='subtitle1' >HANSA Advantage Brochure </Typography>
                  <Typography variant='body2' color={'GrayText'} >How to achieve success and profitable growth by making your organisation automated and process-driven. </Typography>
                </CardContent>
                <CardActions sx={{justifyContent: 'flex-end'}}>
                  <IconButton  onClick={() => window.open("https://erp2enterprise.com/brochures/erp2enterprise-HANSA-brochure.pdf", '_blank')}>
                    <DownloadOutlinedIcon />
                  </IconButton>

                </CardActions>

             
            </Card>
            {/* </a> */}
          
            </div>
           
            
      
            </Grid>

            {/* Grid item 2 - Quick Links */}
            <Grid item xs={12} sm={6} md={3} >
              {/* <Typography align="center" variant="subtitle1" color={'lightGray'} > Quick Links </Typography> 
                 <br/>
              <span>
              <Link to="industry-sec"  activeClass="active" spy={true} smooth={true}  duration={500} >
              
              <Typography align="center" variant="body2" color={'GrayText'} paragraph> Industries </Typography>
              
              </Link>
              </span>
              <Typography align="center" variant="body2" color={'GrayText'} paragraph> Pricing </Typography>
              <Typography align="center" variant="body2" color={'GrayText'} paragraph> Key Modules </Typography>
              <Typography align="center" variant="body2" color={'GrayText'} paragraph> HANSA Advantage </Typography>
              <Typography align="center" variant="body2" color={'GrayText'} paragraph> Teams </Typography>
              <Typography align="center" variant="body2" color={'GrayText'} paragraph> e-Office Brochure </Typography>  */}
              
               
               <ListItem sx={{color: 'lightGray'}}>
                <Typography variant="subtitle1" > Quick Links </Typography> 
                </ListItem>
                <List  sx={{color: "GrayText"}}>
                <ListItemButton onClick={() => scrollToSection("industry-sec")}>
                  Industries
                </ListItemButton>

                <ListItemButton onClick={() => scrollToSection("module-sec")}>
                  Modules
                </ListItemButton>

                <ListItemButton onClick={() => scrollToSection("advantage-sec")}>
                 Advantages Summery
                </ListItemButton>

                <ListItemButton onClick={() => scrollToSection("pricing-sec")}>
                  Pricing
                </ListItemButton>
                
               
                <ListItemButton onClick={() => window.open('https://erp2enterprise.com/brochures/e-Office_Presentation_V1_2021-01-18_SK.pdf', '_blank')}>
                  e-Office Brochure
                </ListItemButton>
                

                {/* <ListItemButton onClick={() => scrollToSection("#")}>
                  e-Office in ULB
                </ListItemButton> */}

                </List>

            </Grid>     {/* grid item 2 ends */}

         
            {/* Grid item 3 - MSME Success Roadmap */}
            <Grid item xs={12} sm={6} md={3} >

                <ListItem sx={{color: 'lightGray'}}>
                <Typography variant="subtitle1" > MSME Success Roadmap </Typography> 
                </ListItem>

                <List  sx={{color: "GrayText"}}>

                <ListItemButton onClick={() => {navigate('system-driven-success'); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  System-driven Success
                </ListItemButton>

                <ListItemButton onClick={() => {navigate('collab-framework-benefits'); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  Collab Framework Benefits
                </ListItemButton>

                <ListItemButton onClick={() => {navigate('low-code-no-code'); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  Low-code / No-code for you
                </ListItemButton>     

                </List>

                {/* Demo & Training */}
                {/* <ListItem sx={{color: 'lightGray'}}>
                <Typography variant="subtitle1" > Demo & Training </Typography> 
                </ListItem>
                <List  sx={{color: "GrayText"}}>

                <ListItemButton onClick={() => scrollToSection("#")}>
                  Introduction
                </ListItemButton>

                <ListItemButton onClick={() => scrollToSection("#")}>
                  Daily Workflow
                </ListItemButton>
                </List> */}

            </Grid>

            {/* Grid item 4 */}
            <Grid item xs={12} sm={6} md={3} >
            <ListItem sx={{color: 'lightGray'}}>
                <Typography variant="subtitle1" > Info </Typography> 
                </ListItem>
                <List  sx={{color: "GrayText"}}>

                <ListItemButton onClick={() => {navigate("about"); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  About
                </ListItemButton>

                {/* on clicking the button navigate to the page and also scroll to the top of the page */}
                <ListItemButton onClick={() => {navigate("privacy-policy"); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  Privacy policy
                </ListItemButton>

                <ListItemButton onClick={() => {navigate("cookies-policy"); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  Cookies Policy
                </ListItemButton>

                <ListItemButton onClick={() => {navigate("terms-and-conditions"); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  Terms of use
                </ListItemButton>

                <ListItemButton onClick={() => {navigate("contacts"); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                  Contacts
                </ListItemButton>

                

                </List>

                



            </Grid>

          </Grid> {/* Grid container ends */}
          
        </Container>

        <br/>



      
     
      {/* Container 2 */}
      {/* <div  style={{backgroundColor: "#2c2c2c", height: "50px"}}> */}
    
      
        <Container>

          <br/>
        <Divider />
        <br/>
         
          <Grid container spacing={2}>

            {/* item 1 logo */}
            <Grid item xs={12} sm={4} md={4}>
          
              <Box

                  component="img"
                  // sx={{ height: 48 }}
                  sx={{ height: 28, display: 'flex', mr: 1  }}
                  // sx={{ height: 52, display: { xs: 'flex', md: 'none' }, mr: 1  }}
                  src={logo}
              />

             
            </Grid>

            {/* Grid item 2 */}
            <Grid item xs={12} sm={4} md={4}>
             
            </Grid>

            {/* Grid item 3 */}
            {/* <Grid item xs={12} sm={4} md={4} sx={{marginTop: "15px"}}> */}
            <Grid item xs={12} sm={4} md={4}>
              <Copyright />
            </Grid>
          </Grid>
          
        </Container>
        <br/>
      </div>

    </>
  )
}

export default Footer

