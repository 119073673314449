import { Typography, Container } from '@mui/material'

import React from 'react'

const TermsAndConditions = () => {
  return (
    <div>
        <Container>
            <br />

            <Typography variant='h3' paragraph>
                Terms and Conditions of using erp2enterprise HANSA
            </Typography>


            <Typography paragraph>
                Welcome to erp2enterprise HANSA! By accessing and using our 
                web-based agile ERP software, you agree to comply with and 
                be bound by the following terms and conditions of use. 
                Please read these terms carefully before using our software.

            </Typography>

            <Typography variant='h6' paragraph>
                1. Ownership
            </Typography>

            <Typography paragraph>
                {/* erp2enterprise HANSA is owned and operated by [insert company name].  */}
                All intellectual property rights, including but not limited to copyright, 
                trademarks, and trade secrets, in and to the software and its content 
                are owned by or licensed to us. You agree not to reproduce, distribute, 
                modify, or create derivative works of any part of our software without 
                our prior written consent.

            </Typography>

            <Typography variant='h6' paragraph>
                2. Use of Software

            </Typography>

            <Typography paragraph>
                You may use erp2enterprise HANSA solely for your own internal business 
                purposes. You agree not to use our software for any illegal or 
                unauthorized purpose, or in any way that violates any applicable 
                laws or regulations. You agree not to access or attempt to access 
                any other user's account, or interfere with the security or integrity of our software.

            </Typography>

            <Typography variant='h6' paragraph>
                3. User Content

            </Typography>

            <Typography paragraph>
                You are responsible for all content that you upload or submit to our 
                software. You represent and warrant that you have the necessary 
                rights and permissions to use and publish any content you upload 
                or submit. You agree not to upload or submit any content that is unlawful, 
                harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.

            </Typography>

            <Typography variant='h6' paragraph>
                4. Fees and Payment

            </Typography>

            <Typography paragraph> 
                You agree to pay all fees and charges associated with your use of 
                erp2enterprise HANSA in accordance with the pricing and payment 
                terms outlined in our software. All fees and charges are non-refundable 
                unless otherwise stated in our software or agreed upon in writing.
            </Typography>

            <Typography variant='h6' paragraph>
                5. Disclaimer of Warranties

            </Typography>

            <Typography paragraph>
                erp2enterprise HANSA is provided "as is" without any warranties, 
                express or implied. We make no representations or warranties of 
                any kind, including but not limited to the accuracy, completeness, 
                reliability, or suitability of our software. We do not guarantee 
                that our software will be error-free or uninterrupted, or that any 
                defects will be corrected.

            </Typography>

            <Typography variant='h6' paragraph>
             6. Limitation of Liability

            </Typography>

            <Typography paragraph>
                In no event shall we be liable for any damages, including but not limited 
                to direct, indirect, incidental, special, or consequential damages arising 
                out of or in connection with your use of our software, even if we have been 
                advised of the possibility of such damages. Our total liability to you shall 
                not exceed the amount you paid for our software.

            </Typography>

            <Typography variant='h6' paragraph >
               7. Indemnification

            </Typography>

            <Typography paragraph>
                You agree to indemnify and hold us harmless from any claims, damages, 
                liabilities, costs, and expenses (including reasonable attorneys' fees) 
                arising out of or in connection with your use of our software or any content 
                you upload or submit.

            </Typography>

            <Typography variant='h6' paragraph>
                8. Termination

            </Typography>

            <Typography paragraph>
                We may terminate your access to erp2enterprise HANSA at any time 
                for any reason without notice. Upon termination, you must immediately 
                stop using our software and destroy all copies of our software in your possession.

            </Typography>

            <Typography variant='h6' paragraph>
                9. Governing Law and Jurisdiction

            </Typography>

            <Typography paragraph>
                These terms and conditions shall be governed by and construed in accordance 
                with the Indian laws. Any disputes arising out of or in 
                connection with these terms and conditions shall be subject to the exclusive 
                jurisdiction of the courts of Kolkata jurisdiction.

            </Typography>

            <Typography variant='h6' paragraph>
                10. Changes to Terms and Conditions

            </Typography>

            <Typography paragraph>
                We reserve the right to change these terms and conditions without 
                notice at any time we may deem suitable.

            </Typography>


        </Container>

        <br />
        <br />
      
    </div>
  )
}

export default TermsAndConditions
