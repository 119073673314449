import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const CollabFrameWorkBenefits = () => {
  return (
    <div>
         <Box
             component="img"
             sx={{ width: "100%", display: 'flex', mr: 1 }}
             // sx={{ height: 52, display: { xs: 'flex', md: 'none' }, mr: 1  }}
             src={"https://erp2enterprise.com/site-images/blog-collaboration.png"}
        >

        </Box>
        
        <Container maxWidth='md'>
          <br/>
          <Typography variant='h2' paragraph>
              Benefits of in-built collaboration framework in Agile ERP
          </Typography>
        </Container>

        <Container maxWidth='md'>
          <Typography color='GrayText' paragraph>
            Having a collaboration framework in an Agile ERP (Enterprisewide Resource Planning) 
            environment can provide several benefits, including:
          </Typography>

          <Container maxWidth='md'>

            <Typography paragraph>
                <Typography >Improved Communication: </Typography> 
                <Typography color='GrayText'>
                  An Agile ERP collaboration framework provides a platform for team 
                  members to communicate more effectively, share information, and 
                  collaborate on tasks. This can help to improve communication and 
                  reduce misunderstandings between team members.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Enhanced Productivity: </Typography> 
                <Typography color='GrayText'>
                  Collaboration within an Agile ERP framework can increase productivity 
                  by providing team members with the tools they need to work together 
                  efficiently. By streamlining processes and making it easier to share 
                  information, team members can work more effectively and complete tasks 
                  more quickly.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Faster Decision-Making:  </Typography> 
                <Typography color='GrayText'>
                  By promoting collaboration and communication, an Agile ERP collaboration 
                  framework can enable faster decision-making. Teams can work together 
                  to evaluate options, weigh the pros and cons, and make decisions more quickly, 
                  reducing delays and keeping projects on track.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Greater Agility:  </Typography> 
                <Typography color='GrayText'>
                  An Agile ERP collaboration framework is designed to be flexible and adaptable 
                  to changing circumstances. This means that teams can respond quickly to new 
                  challenges and opportunities, adjusting processes and workflows as needed 
                  to keep projects moving forward.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Better Quality:  </Typography> 
                <Typography color='GrayText'>
                  Collaboration within an Agile ERP framework can help to ensure that everyone 
                  is working towards the same goal and that quality standards are maintained. 
                  By sharing information and working together, team members can identify issues 
                  early and address them before they become bigger problems.
                </Typography>
            </Typography>

           
           
          </Container>

          {/* <Typography color='GrayText' paragraph>
            In summary, ...
          </Typography> */}

        </Container>
        <br/>
        <br/>
      
    </div>
  )
}

export default CollabFrameWorkBenefits
