import { Typography, Container } from '@mui/material'

import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
        <Container>
            <br/>

            <Typography variant='h3' paragraph>
                Privacy Policy
            </Typography>

            <Typography paragraph>
                At erp2enterprise, we are committed to protecting the 
                privacy of our website visitors and customers. This 
                Privacy Policy explains how we collect, use, and disclose 
                personal information collected through our website, as well 
                as how we protect and secure this information.
                    
            </Typography>

            <Typography variant='h6' paragraph>
                What information do we collect?
            </Typography>

            <Typography paragraph>
                We may collect personal information from you when you use 
                our website, such as your name, email address, phone number, 
                and company information. We may also collect information 
                about how you use our website, including your IP address, 
                browser type, and device information.
            </Typography>

            <Typography variant='h6' paragraph>
                How do we use your information?
            </Typography>

            <Typography paragraph>
                We may use your personal information for a variety of purposes, including:
                <br/>
                <br/>

                - To provide you with our ERP services
                <br/>
                <br/>
                - To communicate with you about our products and services
                <br/>
                <br/>
                - To improve our website and services
                <br/>
                <br/>
                - To comply with legal obligations
                <br/>
                <br/>
                - To prevent fraud and protect our rights
                <br/>
                <br/>
                We will only use your personal information for the purposes 
                for which it was collected, and we will not sell or rent your 
                personal information to third parties.
                    
            </Typography>

            <Typography variant='h6' paragraph>
                How do we protect your information?
            </Typography>

            <Typography paragraph>
                We take appropriate measures to protect your personal information 
                from unauthorized access, use, and disclosure. We use industry-standard 
                security measures such as encryption, firewalls, and secure servers to 
                protect your personal information.

                <br/>

                However, no data transmission over the internet can be guaranteed to be 
                completely secure. As a result, we cannot guarantee the security of any 
                information you transmit to us and you do so at your own risk.

            </Typography>

            <Typography variant='h6' paragraph>
                Do we use cookies?
            </Typography>

            <Typography paragraph>
                Yes, we may use cookies and other tracking technologies to improve our 
                website and services. Cookies are small text files that are placed on 
                your device when you visit our website. They allow us to remember your 
                preferences and personalize your experience on our website.

                <br/>

                You can control cookies through your browser settings, but disabling 
                cookies may limit your ability to use certain features of our website.

            </Typography>

            <Typography variant='h6' paragraph>
                Do we disclose your information to third parties?
            </Typography>

            <Typography paragraph>
                We may disclose your personal information to third parties in the following circumstances:
                <br/>
                <br/>

                - To our service providers who assist us in providing our ERP services
                <br/>
                <br/>
                - To comply with legal obligations or to protect our rights
                <br/>
                <br/>
                
                In connection with a merger, acquisition, or sale of assets
                We will only disclose your personal information to third parties 
                for the purposes for which it was collected, and we will take 
                appropriate measures to ensure that any third parties with whom 
                we share your information are bound by confidentiality and privacy obligations.

            </Typography>


            <Typography variant='h6' paragraph>
                Changes to this Privacy Policy
            </Typography>

            <Typography paragraph>
                We may update this Privacy Policy from time to time. 
                Any changes will be posted on our website, and we 
                encourage you to review this policy periodically.

            </Typography>

            <Typography variant='h6' paragraph>
                Contact Us
            </Typography>


            <Typography paragraph>
                If you have any questions or concerns about this Privacy Policy, 
                please contact us at info@erp2enterprise.com.

            </Typography>

           

        </Container>
        <br/>
        <br/>
      
    </div>
  )
}

export default PrivacyPolicy