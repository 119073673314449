import { Typography, Container } from '@mui/material'

import React from 'react'

const CookiesPolicy = () => {
  return (
    <div>
        <Container>
            <br/>

            <Typography variant='h3' paragraph>
                Cookies Policy
            </Typography>

            <Typography paragraph>
                Welcome to our browser cookie policy for our business 
                solution website. This policy explains how we use cookies 
                on our website and how you can manage them.
                    
            </Typography>

            <Typography variant='h6' paragraph>
                What are cookies?
            </Typography>

            <Typography paragraph>
                Cookies are small text files that are placed on your 
                device when you visit a website. They are widely used 
                to make websites work, or work more efficiently, as well 
                as to provide information to the owners of the site.
            </Typography>

            <Typography variant='h6' paragraph>
                What cookies do we use?
            </Typography>

            <Typography paragraph>
                We use both session cookies and persistent cookies on our 
                website. Session cookies are temporary cookies that expire 
                when you close your browser. Persistent cookies stay on your 
                device until they expire or you delete them.
                    
            </Typography>

            <Typography variant='h6' paragraph>
                We use cookies to:
            </Typography>

            
            <Typography paragraph>
                Remember your preferences and settings
                Improve our website’s functionality and performance
                Monitor website usage and improve our services
                Deliver relevant marketing messages and advertisements
                We may also use third-party cookies, such as Google Analytics, 
                to track website usage and gather information about our website 
                visitors. These cookies are used to improve our website and provide 
                a better user experience.
                    
            </Typography>

            <Typography variant='h6' paragraph>
                How to manage cookies
            </Typography>


            <Typography paragraph>
                You can manage cookies through your browser settings. 
                You can choose to accept or decline cookies. Most web 
                browsers automatically accept cookies, but you can 
                usually modify your browser settings to decline cookies 
                if you prefer.

            </Typography>

            <Typography paragraph>
                Please note that disabling cookies may prevent you from taking 
                full advantage of our website and some of its features may not work 
                as intended.
                
            </Typography>

            <Typography variant='h6' paragraph>
                Updates to this policy
            </Typography>

            <Typography paragraph>
                We may update this cookies policy from time to time to reflect 
                changes to our website and the cookies we use. We encourage you 
                to review this policy periodically for any updates or changes.

            </Typography>

            <Typography variant='h6' paragraph>
                Contact us
            </Typography>

            <Typography paragraph>
                If you have any questions about our cookie policy, 
                please contact us at info@erp2enterprise.com

            </Typography>

        </Container>

        <br/>
        <br/>
      
    </div>
  )
}

export default CookiesPolicy