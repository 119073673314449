import { Typography, Button, Container, ButtonGroup, Grid, Box } from '@mui/material';
import { green, purple } from '@mui/material/colors';
import Advantages from '../components/Advantages';
import CarouselTop from '../components/CarouselTop';
import IndustryCards from '../components/IndustryCards';
import Modules from '../components/Modules';
import Numbers from '../components/Numbers';
import Pricing from '../components/Pricing';
import Solutions from '../components/Solutions';
import Teams from '../components/Teams';
import UseCases from '../components/UseCases';





const LandingPage = () => {
 

  return (
    <>
      {/* Carousel for medium and larger scrren */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' }}}> */}
      <Box >
        <CarouselTop/>
      </Box>

      {/* What is HANSA - main body */}
      <Container >
        <Typography variant='h6' align='center'>HANSA is the next generation upgrade of agile ERP RESOLUTE -
      proven to run non-stop for more than 12 years. 
        </Typography> 

        <br />

       <Typography variant='h6' color='gray' align='center'>  HANSA makes your key business processes system-driven. 
        It empowers your employees to collaborate and manage sales, procurement, production, 
        inventory, e-invoicing and finance seamlessly.
        </Typography>

        <br /> 
        <br />
      
      </Container>

      
      {/* industries section */}
      <span id='industry-sec' /> 
      <br/>
      <br/>
      <br/>
      <IndustryCards />


      {/* prices section */}
      <span id='pricing-sec' /> 
      <br/>
      <br/>
      <br/>
      <Pricing />
     

      {/* Solutions section */}

      {/* <Solutions /> */}

      {/* Validating numbers section */}
      <span id='number-sec' /> 
      <br/>
      <br/>
      <br/>
      <Numbers />

      {/* Modules section */}
      <span id='module-sec' /> 
      <br/>
      <br/>
      <br/>
      <Modules />

      {/* The HANSA Advantages section */}
      <span id='advantage-sec' /> 
      <br/>
      <br/>
      <br/>
      <Advantages />

      {/* Teams for excellence and innovation section */}
      <span id='team-sec' /> 
      <br/>
      <br/>
      <br/>
      <Teams />

      {/* Use Cases section */}

      {/* <UseCases /> */}

    </>
    
    
    
  )
}

export default LandingPage