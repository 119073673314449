import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { Routes, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import Footer from "../components/Footer";

import { useEffect, useState } from "react";
import { Fab, IconButton } from "@mui/material";
// scroll up icons
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import TermsAndConditions from "../pages/info/TermsAndConditions";
import PrivacyPolicy from "../pages/info/PrivacyPolicy";
import CookiesPolicy from "../pages/info/CookiesPolicy";
import About from "../pages/info/About";
import Contacts from "../pages/info/Contacts";
import SystemDrivenSuccess from "../pages/blogs/SystemDrivenSuccess";
import CollabFrameWorkBenefits from "../pages/blogs/CollabFrameWorkBenefits";
import LowCodeNoCode from "../pages/blogs/LowCodeNoCode";



const MainLayout = () => {

    // for sctoll to top button
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    useEffect(() => {
      // Button is displayed after scrolling for 300 pixels
      const handleScrollButtonVisibility = () => {
        window.pageYOffset > 300 ? setShowScrollTopButton(true) : setShowScrollTopButton(false);
      };
  
      window.addEventListener('scroll', handleScrollButtonVisibility);
  
      return () => {
        window.removeEventListener('scroll', handleScrollButtonVisibility)
      };
    }, []);


  return (
    <div>
        {/* Header section here */}
         <ResponsiveAppBar />


        <Routes>
            {/* Landing page here */}
            <Route path='/' element={<LandingPage />}></Route>
        </Routes>

        <Routes>
          {/* Info ------------------------------------------------------------ */}
            {/* Terms and Condition page here */}
            <Route path='terms-and-conditions' element={<TermsAndConditions />}></Route>

            {/* Privacy Policy page here */}
            <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>

             {/* Cookies policy page here */}
             <Route path='cookies-policy' element={<CookiesPolicy />}></Route>

             {/* About page here */}
            <Route path='about' element={<About />}></Route>

             {/* About page here */}
             <Route path='contacts' element={<Contacts />}></Route>

            {/* Blogs ------------------------------------------------------------ */}

             {/* System-driven success page here */}
             <Route path='system-driven-success' element={<SystemDrivenSuccess />}></Route>

             {/* Collab framework benefits page here */}
             <Route path='collab-framework-benefits' element={<CollabFrameWorkBenefits />}></Route>

             {/* Low-code no--code benefits page here */}
             <Route path='low-code-no-code' element={<LowCodeNoCode />}></Route>


        </Routes>

       

         {/* scroll to top sticky button */}
         { showScrollTopButton &&
            <Fab 
              size="small" 
              sx={{ position: 'fixed',  bottom: 16, right: 16, backgroundColor: 'hsla(0, 0%, 58%, 0.3 )'}}
              onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}}
              >
            <KeyboardDoubleArrowUpOutlinedIcon fontSize="small"/>
          </Fab>
         }   
       


        {/* Footer section */}

        <Footer />

        
           
    </div>
  )
}

export default MainLayout