import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Grid } from '@mui/material';



interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
})); 


export default function IndustryCards() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  return (
    <>
    <Container>
        <Typography align="center" variant="h4"  > Industries </Typography>
        <Typography align="center" variant="subtitle1" paragraph> comprehensive industry specific solutions </Typography>
        {/* <br /> */}

    </Container>
    
    <Container style={{ background: 'hsla(0,0%, 51%, 0.0)' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
            
        {/* Grid item 1 - Parts and Components Industries */}

        <Grid item xs={12} sm={6} md={3}>
            
          <Card sx={{maxWidth: { xs: 'none', sm: 345, md: 345 }}} >
         
            <CardHeader
              // style={{ textAlign: 'center', display:"flex", alignItems: 'center', justifyContent: 'center' }}
              title="Parts & Components"
              subheader="Autoparts, engineering, plastic etc"
            />
            <CardMedia
              component="img"
              height="194"
              // image="/static/images/cards/paella.jpg"
              image={"https://erp2enterprise.com/site-images/industries-autopart-2-small.png"}
              alt="Paella dish"
            />
            <CardContent sx={{height: '10vw'}}>
              <Typography variant="body2" color="text.secondary">
                HANSA addresses all the industry-specific 
                pain areas out of the box for Ancillaries and 
                Part Manufacturers like job-work, customer-batch,
                 heat number tracking, multi-process BOMS and 
                 agile production scheduling.


              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>Industry-specific Features </Typography>
                <Typography paragraph>
                  Finite capacity computation on-the-fly helps you 
                  in agile planning and scheduling, much needed to 
                  keep place with ever-changing customer delivery schedule.
                </Typography>
                <Typography paragraph>
                  Job-work in-and-out is handled seamlessly to keep a close 
                  control over the job-work suppliers.
                </Typography>
                <Typography paragraph>
                  End-to-end tracking of materials helps 
                  to comply to the requirement of tracking 
                  customer batch number, heat number for steel
                  and manufacturer's batch number.
                </Typography>
                
              </CardContent>
            </Collapse>
          </Card>
          
        </Grid> 

        {/* Grid item 2 - Equipment and OEM Manufacturers */}

        <Grid item xs={12} sm={6} md={3}>
            
          <Card sx={{maxWidth: { xs: 'none', sm: 345, md: 345 }}}>
            <CardHeader
              title="Equipment"
              subheader="OEMs, vehicles, mechineries"
            />
            <CardMedia
              component="img"
              height="194"
              // image="/static/images/cards/paella.jpg"
              image={"https://erp2enterprise.com/site-images/industries-equipment.jpg"}
              alt="Paella dish"
            />
            <CardContent sx={{height: '10vw'}}>
              <Typography variant="body2" color="text.secondary">
                HANSA for Vehicle and Equipment manufacturers 
                solves the problem of VIN and serial number
                based tracking for the entire equipment 
                lifecycle. 
                
                Optional custom modules for dealer portal,
                supplier portal and D2C can be integrated 
                seamlessly. 
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>

                <Typography paragraph>Industry-specific Features</Typography>

                <Typography paragraph>
                  HANSA for Vehicle and Original Equipment 
                  Manufacturers (OEMs) provides detailed planning 
                  and scheduling based on finite capacity. 
                </Typography>

                <Typography paragraph>
                  Handling of multi-level BOMs for assembly line 
                  with detailed test procedures of each step 
                  enables comprehensive Quality Assurance (QA).
                </Typography>

                <Typography paragraph>
                  Seamless integration with supplier portal 
                  provides non-stop operation at assembly lines.
                </Typography>
                <br />
              </CardContent>
            </Collapse>
          </Card>
          
        </Grid>

        {/* Grid item 3 - Process and Chemical Industries */}

        <Grid item xs={12} sm={6} md={3}>
            
          <Card sx={{maxWidth: { xs: 'none', sm: 345, md: 345 }}}>
            <CardHeader
              title="Process Industries"
              subheader="Chemicals, paints, pharma API"
            />
            <CardMedia
              component="img"
              height="194"
              // image="/static/images/cards/paella.jpg"
              image={"https://erp2enterprise.com/site-images/industries-process.jpg"}
              alt="Paella dish"
            />
            <CardContent sx={{height: '10vw'}}>
              <Typography variant="body2" color="text.secondary">
                HANSA for Chemical, Pharma, API and other 
                Process Industries focuses on batch based
                manufacturing that enables you to track 
                exactly which input material batch of which 
                shipment has gone in which output batch.
                
                
                
                {/* tracking of
                input vs output material batches. */}

              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>Industry-specific Features</Typography>
                <Typography paragraph>
                  HANSA for Process Industries provides total 
                  quality management through end-to-end QC 
                  batch based tracking. 
                  
                </Typography>

                <Typography paragraph>
                  Cost of output 
                  batch is calculated with exact Landing
                  cost of raw materials and packing materials.

                </Typography>

                <Typography paragraph>
                  Multi-level BOMs / formulation can be 
                  maintained with ease and flexibility.  
                </Typography>

                <Typography paragraph>
                  Materials are issued based on earliest 
                  expiry date to minimize loss due to 
                  aging.
                </Typography>


              </CardContent>
            </Collapse>
          </Card>
          
        </Grid> 

        {/* Grid item 4 - e-Office for ULBs and Departments */}

        <Grid item xs={12} sm={6} md={3}>
            
          <Card sx={{maxWidth: { xs: 'none', sm: 345, md: 345 }}}>
            <CardHeader
              title="e-Office"
              subheader="Municipalities and departments"
            />
            <CardMedia
              component="img"
              height="194"
              // image="/static/images/cards/paella.jpg"
              image={"https://erp2enterprise.com/site-images/e-office-ULB-small.png"}
              alt="Paella dish"
            />
            <CardContent sx={{height: '10vw'}}>
              <Typography variant="body2" color="text.secondary">
                E-Office manages the entire gamut of activities of an
                organisation in an efficient, secured and co-ordinated
                manner. It is a ready collaboration framework, fully configurable
                to suit the requirements of the organization.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>What e-Office does</Typography>
                <Typography paragraph>
                  E-Office gives total visibility of an organization increasing security and
                  productivity along with streamlining documents and processes.
                </Typography>

                <Typography paragraph>
                  It provides process-based flow of documents with facility of attachment
                  and secured retrieval of files and scanned documents.

                </Typography>

                <Typography paragraph>
                  Options for customization and addition of forms and reports
                  is provided as per your requirement to scale it up to 
                  a complete enterprise system.

                </Typography>


                {/* <Typography paragraph>Why e-Office</Typography>
                <Typography paragraph>
                  - Bringing back Accountability, Responsibility and Collaboration. <br/>
                  - Collaboration among departments and people. <br/>
                  - Streamlining people and processes. <br/>
                  - Having complete control and secured access over files and documents. 
                </Typography> */}
            
              </CardContent>
            </Collapse>
          </Card>
          
        </Grid> 


      </Grid> {/* Grid container ends here */}

    </Container>
    <br />
    <br />
    </>

    
  );
}
