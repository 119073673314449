import React from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Container
} from "@mui/material";



const Contacts = () => {

  return (
    <div>
       <Box
             component="img"
             sx={{ width: "100%", display: 'flex', mr: 1 }}
             // sx={{ height: 52, display: { xs: 'flex', md: 'none' }, mr: 1  }}
             src={"https://erp2enterprise.com/site-images/about.jpg"}
        >
        </Box> 

        <Container>
            <br />

            <Container maxWidth='xs'>
            <Typography variant='h3' paragraph>
                Contact us
            </Typography>
            </Container>

            <Container maxWidth='xs'>

                <Typography paragraph>
                    For your enquiries, please contact 
                </Typography>

                <Typography paragraph>
                    MINDKORP TECHNOLOGIES PRIVATE LIMITED
                    <br/>
                    Email - info@mindkorp.com
                </Typography>

                <br/>
                <br/>

                <Typography paragraph>
                    For support, please raise a support ticket from 
                    support module within the ERP. 
                    <br/>
                    Email - support@erp2enterprise.com
                </Typography>

            </Container>


        </Container>
      <br/>
      <br/>
    </div>
  )
}

export default Contacts