import { Typography, Container, Grid, Box } from '@mui/material';
import { Card, CardHeader, CardMedia, CardContent } from '@mui/material';
import TeamImage1 from '../assets/images/carousel-images/pexels-thisisengineering-small.png';
import TeamImage2 from '../assets/images/carousel-images/tech2-large.png';
import TeamImage3 from '../assets/images/carousel-images/best-practices-small.png';
import TeamImage4 from '../assets/images/carousel-images/pexels-thisisengineering-small.png';

const Modules = () => {
  return (
    <div>
    {/* Section Header */}
    <Container>
        <Typography align="center" variant="h4"  > Key Modules </Typography>
        <Typography align="center" variant="subtitle1" paragraph> best practices out of the box </Typography>
        <br />

    </Container>

    <Container>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>

            {/* Grid item 1 - Planning and scheduling */}
            <Grid item xs={12} sm={6} md={4} >

              <Card>
                <CardMedia
                   component="img"
                   height="196"
                   image={"https://erp2enterprise.com/site-images/modules-planning-scheduling.png"}
                   alt="Unit 1"
                />           
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>PLANNING & SCHEDULING</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                  Finite capacity planning and agile
                  scheduling enables you with lean
                  manufacturing. This releases money
                  locked into the system and let you use
                  that money for growth.
                  </Typography>
                </CardContent>

              </Card>

            </Grid>

            {/* Grid item 2 - Analytic dashboard */}
            <Grid item xs={12} sm={6} md={4} >

              <Card>
                <CardMedia
                   component="img"
                   height="196"
                   image={"https://erp2enterprise.com/site-images/modules-analytics-small.png"}
                   alt="Unit 1"
                />           
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>ANALYTICS DASHBOARD</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                    Providing key information at your
                    fingertips, the dashboards help you to
                    keep focus and lead the organization to
                    success and growth.
                  </Typography>
                </CardContent>

              </Card>

            </Grid>

            {/* Grid item 3 -sales and distribution*/}
           <Grid item xs={12} sm={6} md={4} >

              <Card>
                <CardMedia
                   component="img"
                   height="196"
                   image={"https://erp2enterprise.com/site-images/modules-sales-distribution-2-small.png"}
                   alt="Unit 1"
                />           
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>SALES & DISTRIBUTION</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                    Automate your sales and distribution
                    process. Ship fast, flawlessly with built-in
                    real time e-Invoicing. Achieve better
                    compliance and enhance delivery date
                    performance.
                  </Typography>
                </CardContent>

              </Card>

            </Grid>

            {/* Grid item 4 -Purchase and inventory */}
            <Grid item xs={12} sm={6} md={4} >

              <Card>
                <CardMedia
                   component="img"
                   height="196"
                   image={"https://erp2enterprise.com/site-images/modules-purchase-inventory-small.png"}
                   alt="Unit 1"
                />           
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>PURCHASE & INVENTORY</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                    Track and optimise cost and quality by
                    each incoming shipment. Get the
                    control back on raw materials,
                    consumables, engineering purchases
                    with batch & serialized stock
                    management.
                  </Typography>
                </CardContent>

              </Card>

            </Grid>

             {/* Grid item 5 -Production and QC */}
            <Grid item xs={12} sm={6} md={4} >

              <Card>
                <CardMedia
                   component="img"
                   height="196"
                   image={"https://erp2enterprise.com/site-images/modules-production-qc-alt2-small.png"}
                   alt="Unit 1"
                />           
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>PRODUCTION & QC</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                    Enable end-to-end tracking of materials
                    from scheduling to delivery. In-process
                    and final product QC helps you achieve
                    cost savings, better delivery
                    performance and better quality.
                  </Typography>
                </CardContent>

              </Card>

            </Grid>

             {/* Grid item 6 -account and finance */}
            <Grid item xs={12} sm={6} md={4} >

              <Card>
                <CardMedia
                   component="img"
                   height="196"
                   image={"https://erp2enterprise.com/site-images/modules-accounts-finance-small.png"}
                   alt="Unit 1"
                />           
                
                <CardContent sx={{minHeight: { xs: 'none', sm: '14vw', md: '14vw' }}}>
                  <Typography variant='h6' align='center'>ACCOUNTS & FINANCE</Typography>
                  <Typography variant='subtitle2' color={'GrayText'} align='center'>
                    A versatile & robust GL based
                    accounting system helps you keep tight
                    control on finance and saves you money.
                  </Typography>
                </CardContent>

              </Card>

            </Grid>

          </Grid> {/* Grid container ends */}
          
        </Container>
        <br /> 
        <br/>

    
      
    </div>
  )
}

export default Modules
