import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const LowCodeNoCode = () => {
  return (
    <div>
         <Box
             component="img"
             sx={{ width: "100%", display: 'flex', mr: 1 }}
             // sx={{ height: 52, display: { xs: 'flex', md: 'none' }, mr: 1  }}
             src={"https://erp2enterprise.com/site-images/blogs-no-code-low-code-alt.png"}
        >

        </Box>

        <Container maxWidth='md'>
          <br/>
          <Typography variant='h2' paragraph>
              Benefits of low-code no-code technology for you
          </Typography>
        </Container>

        <Container maxWidth='md'>
          <Typography color='GrayText' paragraph>
            Using a low-code or no-code Agile ERP (Enterprise Resource Planning) solution 
            can provide several benefits for small and medium-sized enterprises (SMEs), 
            including:
          </Typography>

          <Container maxWidth='md'>

            <Typography paragraph>
                <Typography >Cost-Effective: </Typography> 
                <Typography color='GrayText'>
                  Low-code or no-code Agile ERP solutions often have lower upfront costs, as they 
                  drastically reduce customization and code modification time and results in 
                  faster implementation.
                  This can be particularly beneficial for SMEs with limited budgets.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Faster Deployment: </Typography> 
                <Typography color='GrayText'>
                  Low-code or no-code Agile ERP solutions can be deployed faster than traditional 
                  ERP systems. With pre-built templates and a user-friendly interface, these 
                  solutions can be up and running in a matter of weeks rather than months, 
                  allowing SMEs to start reaping the benefits sooner.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Increased Agility:  </Typography> 
                <Typography color='GrayText'>
                  Low-code or no-code Agile ERP solutions are designed to be flexible and adaptable, 
                  allowing SMEs to respond quickly to changing circumstances. This can help SMEs 
                  stay competitive in a rapidly changing business landscape.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Greater Efficiency:  </Typography> 
                <Typography color='GrayText'>
                  With a low-code or no-code Agile ERP solution, SMEs can automate many of their 
                  business processes, reducing the need for expensive customizations and code 
                  modification for each feature and document. Instead, the configuration takes
                  care of much of the specific functionality as required.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Better Visibility:  </Typography> 
                <Typography color='GrayText'>
                  Low-code or no-code Agile ERP solutions can provide SMEs with greater visibility 
                  into their business operations. With real-time data and analytics, SMEs can make 
                  better-informed decisions and identify potential issues before they become major 
                  problems.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Improved Collaboration:  </Typography> 
                <Typography color='GrayText'>
                  Low-code or no-code Agile ERP solutions often have collaboration features 
                  built-in, allowing teams to work together more effectively. This can help 
                  to improve communication, reduce errors, and streamline workflows.
                </Typography>
            </Typography>

            <Typography paragraph>
                <Typography >Scalability: </Typography> 
                <Typography color='GrayText'>
                  As SMEs grow, they need an ERP system that can grow with them. 
                  Low-code or no-code Agile ERP solutions are designed to be scalable, 
                  llowing SMEs to add new functionality and capabilities as needed without 
                  the need for a major overhaul.
                </Typography>
            </Typography>
           
          </Container>
          <Typography color='GrayText' paragraph>
            In summary, using a low-code or no-code Agile ERP solution can provide SMEs 
            with a cost-effective, flexible, and scalable ERP system that can help them 
            streamline their business processes, increase efficiency and productivity, 
            and stay competitive in a rapidly changing business landscape.

          </Typography>

        </Container>
        <br/>
        <br/>
      
    </div>
  )
}

export default LowCodeNoCode
