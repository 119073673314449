
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logo from '../assets/images/erp2enterprise-logo.png';
import * as Scroll from 'react-scroll';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';



// Section lists to be used in both large and small screen differently
const sections = [
  {name: 'pricing', id: 'pricing-sec'},
  {name: 'Industries', id: 'industry-sec'},
  {name: 'Modules', id: 'module-sec'}
];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];



function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


 

  return (
    // <AppBar position="sticky" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }} >
    <AppBar position="static" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }} >
      <Container maxWidth="xl">
        <Toolbar disableGutters variant='dense'>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
         
          {/* erp2enterprise colorfull text - visible only on large screen */}
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 1,
              display: { xs: 'none', md: 'flex' },
              // fontFamily: 'monospace',
              fontWeight: 500,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <span style={{ color: 'orange' }}>erp2</span>
            <span style={{ color: 'white' }}>enterprise</span>
            
          </Typography> 
          
          {/* MenuIcon visible only on small screen */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            
              {/* Sections as menuitems -- visible only om small screen */}
              {sections.map((section) => (
                <Link activeClass="active" to={`${section.id}`} spy={true} smooth={true}>
                <MenuItem key={section.name} onClick={handleCloseNavMenu} >
                  <Typography textAlign="center">{section.name}</Typography>
                </MenuItem>
                </Link>
              ))}
             
              
            </Menu>
          </Box>

         
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}

           {/* erp2enterprise color text - visible only on small screen*/}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 1,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              // fontFamily: 'monospace',
              fontWeight: 500,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <span style={{ color: 'orange' }}>erp2</span>
            <span style={{ color: 'white' }}>enterprise</span>
          </Typography>

          {/* Box for section lists visible in medium and large screen */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            {/* Section buttons -- for large screen */}
            {sections.map((section) => (
              <Link activeClass="active" to={`${section.id}`} spy={true} smooth={true}>
              <Button
              key={section.name}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {section.name}
            </Button>
            </Link>
            ))}

          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: '25px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          {/* Logo at right corner */}
          <NavLink to="/">
            <Box
                component="img"
                // sx={{ height: 48 }}
                sx={{ height: 48, display: 'flex', mr: 1  }}
                // sx={{ height: 52, display: { xs: 'flex', md: 'none' }, mr: 1  }}
                src={logo}
            />
          </NavLink>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;