import React from 'react';
import { Typography, Link } from '@mui/material';


const Copyright = () => {
  return (
    <>
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://erp2enterprise.in">
        erp2enterprise
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
    </>
 
  )
}

export default Copyright