import React from 'react';
import { Typography, Container, Box } from '@mui/material';


const About = () => {
  return (
    <div>
        <Box
             component="img"
             sx={{ width: "100%", display: 'flex', mr: 1 }}
             // sx={{ height: 52, display: { xs: 'flex', md: 'none' }, mr: 1  }}
             src={"https://erp2enterprise.com/site-images/about.jpg"}
        >

        </Box>
        
        <Container>

            <br/>

            <Typography variant='h2' paragraph>
                About erp2enterprise
            </Typography>

            <Typography paragraph>
                erp2enterprise is a top of the line agile ERP that 
                takes charge of the entire gamut of processes to run
                 your organization efficiently. 
                 <br/>
                 This web-based application platform completely takes 
                 care of day-to-day management, operations, compliance 
                 and control of the enterprise, to save substantial time 
                 and money.
               
            </Typography>

            <Typography paragraph>
                With a process oriented collaboration framework, the 
                solution creates an enterprise level backbone for 
                excellence and performance. The core framework is 
                especially useful to achieve better control and 
                responsiveness in public and private sector enterprises.  
               
            </Typography>

            <Typography paragraph>
                Distributed on-premises or on-demand as per requirement, 
                this cloud-based ERP provides the best of both SAAS vs. 
                in-premise systems. 
                
                
            </Typography>

            <Typography paragraph>
                erp2enterprise is running successfully in engineering, 
                equipment & component manufacturers, auto-part & automobile, 
                chemical & process industries for more than 12 years.
                
            </Typography>

            <br/>
            <br />

        </Container>
      
    </div>
  )
}

export default About